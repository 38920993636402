<template>
  <div class="amount-form">
    <p class="amount-form__title" :class="textColor">
      {{ questionText }}
    </p>
    <form class="amount-form__form" @submit.prevent="submit">
      <div
        class="input-container amount-form__container"
        :class="[
          !isValid && errorInputBorder ? 'input-container--red-border' : '',
        ]"
      >
        <span class="currency">£</span>
        <BaseInput
          ref="input"
          v-model="userInput"
          :pattern="numericalInputPattern"
          inputmode="numeric"
          type="text"
          class="d-inline input"
          :placeholder="placeholder"
        />

        <Button
          class="button-partner"
          :text="buttonText"
          :is-squared-button="true"
          :disabled="!isValid"
          :right-arrow="true"
          :class-prop="buttonBg"
          right-arrow-color="white"
        />

        <span v-if="!isValid" class="error">
          {{ validationErrorMessage }}
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import BaseInput from '@mic/frontend/inputs/BaseInput'
import Button from '@/components/shared/Button'
import { mapGetters } from 'vuex'
import getNextQuestion from '@/helpers/questions/getNextQuestion'
import { AMOUNT } from '@/helpers/constants/question-slugs'
import { numericalInputPattern } from '@/helpers/constants/regex-patterns'

export default {
  name: 'LoanInputHome',
  components: {
    BaseInput,
    Button
  },
  props: {
    buttonText: {
      type: String,
      default: 'Find a loan'
    },
    questionText: {
      type: String,
      default: 'How much are you looking to borrow?'
    },
    placeholder: {
      type: String,
      default: 'Enter the desired amount'
    },
    min: {
      type: Number,
      default: 1000
    },
    errorInputBorder: {
      type: Boolean,
      default: false
    },
    delayNext: {
      type: Boolean,
      default: false
    },
    focusForm: {
      type: Boolean,
      default: true
    },
    textColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      userInput: '',
      isValid: true,
      format: new Intl.NumberFormat('en-GB').format,
      numericalInputPattern
    }
  },
  computed: {
    ...mapGetters(['getPartner', 'getJourney', 'buttonBg']),
    checkInput () {
      return this.userInput
    },
    validationErrorMessage () {
      if (!this.isValid) {
        return `Please enter at least £${this.format(this.min)}`
      } else {
        return ''
      }
    }
  },
  watch: {
    userInput (val) {
      // remove comma
      let formatted = val.replace(/\D+/g, '')
      // format to bgp
      this.userInput = new Intl.NumberFormat('en-GB').format(formatted)

      if (val.length > 2) {
        this.validateInput()
      }
    }
  },
  mounted () {
    if (this.focusForm) {
      this.focus()
    }
  },
  methods: {
    submit () {
      // validate as button will be set to valid at start
      this.validateInput()
      let formattedInput = this.userInput.replace(/\D+/g, '')
      // if valid amount we send this amount to the store
      if (this.isValid) {
        const payload = {
          questionId: 'qid_amount',
          answer: { result: formattedInput }
        }
        this.$store.dispatch('actionSetAnswer', payload)
        // get the next question, taking into account which journey we need to use
        const nextQuestion = getNextQuestion(
          AMOUNT,
          payload.answer,
          this.getJourney
        )
        this.$emit('next')
        if (this.delayNext) {
          setTimeout(() => {
            this.$router.push(`/question/${nextQuestion.slug}`)
          }, 500)
        } else {
          this.$router.push(`/question/${nextQuestion.slug}`)
        }
      }
    },
    validateInput () {
      // to number and remove comma
      let formattedInput = parseInt(this.userInput.replace(/\D+/g, ''))
      // check if higher than min amount and set valid
      if (formattedInput >= this.min) {
        this.isValid = true
      } else {
        this.isValid = false
      }
    },
    focus () {
      // focus to begin inside input
      const { input } = this.$refs
      if (input && input.$el) {
        input.$el.focus()
      }
    }
  }
}
</script>

<style lang="scss">
.amount-form {
  .button-partner {
    span.button-text {
      display: none;
      font-size: 16px;

      @media (min-width: $md-width) {
        display: inline;
      }
    }

    .button {
      @media (max-width: 999px) {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        margin-top: 5px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.amount-form {
  @media (min-width: $md-width) {
    padding-top: 40px;
  }

  @media (min-width: $lg-width) {
    padding-bottom: 95px;
  }

  &__form {
    position: relative;

    @media (min-width: 370px) {
      width: 355px;
    }

    @media (min-width: $md-width) {
      width: 500px;
    }
  }

  &__container {
    box-shadow: unset;
    font-size: 16px;
    border: 1px solid #deddde;
    border-radius: 6px;
  }

  .input-container {
    margin: 0;
    position: relative;

    @media (max-width: 991px) {
      height: 65px;
    }

    &--red-border {
      border: 1px solid red !important;
    }

    .input {
      font-size: 18px;

      &::placeholder {
        @media (max-width: 360px) {
          font-size: 14px;
        }
      }
    }
    .currency {
      font-size: 30px;
      border-right: 1px solid #f1f1f1;

      @media (max-width: 991px) {
        line-height: 60px;
      }
    }

    .button-partner {
      width: auto;
      align-items: center;

      @media (max-width: 333px) {
        right: 20px;
      }

      @media (max-width: 369px) {
        position: relative;
      }

      @media (max-width: 999px) {
        min-width: unset;
      }

      @media (min-width: $md-width) {
        width: 161px;
      }

      span {
        font-size: 16px;
      }
    }
  }

  &__title {
    text-align: left;
    font-weight: bold;
    color: $black;
    font-size: 16px;
    margin-bottom: 10px;

    &--navy {
      color: $main-title-color;
    }

    @media (min-width: $md-width) {
      font-size: 18px;
    }
  }
}

.input-container .currency {
  border-right: 1px solid rgba(3, 41, 66, 0.5);
  font-size: 40px;
  padding-right: $spacing-12;
  padding-left: $spacing-8;
  margin-right: $spacing-12;
  line-height: 80px - (12px * 2) - 6px; // height - padding - offset
  font-weight: 600;
}

.error {
  color: red;
  position: absolute;
  bottom: -45px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: $md-width) {
    bottom: -35px;
    width: auto;
  }
}

.input {
  line-height: 16px;
}

@media (max-width: $mobile-breakpoint-max) {
  .input-container .currency {
    font-size: 28px;
  }

  .input-container {
    line-height: 56px;
    font-size: 16px;
  }

  .error {
    font-size: 16px;
  }
}
</style>
